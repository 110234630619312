
import Home from '../pages/home'
import Timestamp from '../pages/timestamp'
import WX from '../pages/wx'
import Json from '../pages/json'
import Qrcode from '../pages/qrcode'
import Chat from '../pages/chat'
import Json2Table from '../pages/json2table'

const menus = [
	{
		path: '/',
		redirect: '/home'
	},
	{
		path: '/home',
		component: Home
	},
	{
		path: '/timestamp',
		component: Timestamp,
		meta: {
			title:'时间戳转换',
		},
		index: "1"
	},
	{
		path: '/wx',
		component: WX,
		meta: {
			title:'五行',
		},
		// index: "2"
	},
	{
		path: '/json',
		component: Json,
		meta: {
			title:'JSON格式化',
		},
		index: "3"
	},
	{
		path: '/qrcode',
		component: Qrcode,
		meta: {
			title:'二维码生成',
		},
		index: "4"
	},
	{
		path: '/json2table',
		component: Json2Table,
		meta: {
			title:'JSON转表格',
		},
		index: "5"
	},
	{
		path: '/chat',
		component: Chat,
		meta: {
			title:'Chat',
		}
	}
];

export default menus;