<template>
  <div class="WX">
	<div class="dt">
		生日：<input v-model="yy" placeholder="年"/>年<input v-model="mm" class="mm" placeholder="月"/>月<input v-model="dd" class="dd" placeholder="日"/>日<input v-model="hh" class="dd" placeholder="时"/>时<button @click="test()">测试</button>
	</div>
	<div class="err" v-if="err">
		{{ err }}
	</div>
	<div class="gz" v-if="gzdt.gzYear">
		<div class="gzi">{{ gzdt.gzYear }}年</div>
		<div class="gzi">{{ gzdt.gzMonth }}月</div>
		<div class="gzi">{{ gzdt.gzDay }}日</div>
		<div class="gzi">{{ gzdt.gzHour }}时</div>
	</div>
	<div class="wx" v-if="wx.Jin || wx.Mu || wx.Shui || wx.Huo || wx.Tu">
		<h1>五行得分</h1>
		<table class="table" cellspacing="0" cellpadding="0">
			<tr>
				<th>金</th>
				<th>木</th>
				<th>水</th>
				<th>火</th>
				<th>土</th>
			</tr>
			<tr>
				<td>{{ wx.Jin }}</td>
				<td>{{ wx.Mu }}</td>
				<td>{{ wx.Shui }}</td>
				<td>{{ wx.Huo }}</td>
				<td>{{ wx.Tu }}</td>
			</tr>
			<tr v-if="wx.wx">
				<td colspan="5">
					{{ wx.wx }}旺建议：{{ wx.tips }}
				</td>
			</tr>
		</table>
	</div>
  </div>
</template>

<script>
import calendar from '../js/util.js'
export default {
  name: 'WX',
  components: {
    
  },
  data(){
	return {
		yy: 2013,
		mm: 9,
		dd: 10,
		hh: 0,
		gzdt:{},
		wx:{},
		err: ''
	}
  },
  created(){
	var dt = new Date()
	this.yy = dt.getFullYear()
	this.mm = dt.getMonth() + 1
	this.dd = dt.getDate()
  },
  methods: {
	test(){
		this.gzdt = {}
		this.wx = {}
		this.err = ''
		let yy = parseInt(this.yy)
		let mm = parseInt(this.mm)
		let dd = parseInt(this.dd)
		let hh = parseInt(this.hh)
		if(yy < 1900 || yy > 2100) {
			this.err = '年份不合法'
			return
		}
		if(mm < 0 || mm > 12) {
			this.err = '月份不合法'
			return
		}
		if(dd < 0 || dd > 31) {
			this.err = '日期不合法'
			return
		}
		if(hh < 0 || hh > 24) {
			this.err = '小时不合法'
			return
		}
		this.gzdt = calendar.getGZ(yy, mm, dd, hh)
		this.wx = calendar.getWX(this.gzdt.gzYear, this.gzdt.gzMonth, this.gzdt.gzDay, this.gzdt.gzHour)
		console.log(this.wx)
	}
  }
}
</script>

<style>
.WX {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
  margin: 0 auto;
  width: 300px;
  border: 1px solid #cccccc;
  padding:20px;
  font-size:14px;
  
}
input{
	border: 1px solid #eeeeee;
	width: 50px;
	height: 20px;
	line-height: 20px;
	text-align: right;
}
input.mm,input.dd {
	width:20px;
}
button{
	margin-left:10px;
	border: 1px solid royalblue;
	background-color: royalblue;
	color: white;
}
.err {
	padding: 10px;
	color: red;
}
.gz,.wx{
	padding: 10px;
}
.gzi{
	padding:4px;
	display:inline;
	width:80px;
	height:20px;
	line-height:20px;
}
h1{
	font-size:14px;
}
.table{
	width:100%;
    border-collapse: collapse;
    border-spacing: 0;
}
th,td{
	border:1px solid #ccc;
	padding:5px;
}
th {
	background-color: lightcyan;
}
</style>
