<template>
	<div class="json">
		<div class="box">
			<div class="row">
				宽度：<el-input v-model="options.width"/>
				边框：<el-input v-model="options.margin"/>
				前景色：<el-color-picker v-model="options.color.dark" size="medium"></el-color-picker>
				背景色：<el-color-picker v-model="options.color.light" size="medium"></el-color-picker>
			</div>
			<div class="row">
				<div class="col colHeader">
					<b>二维码内容</b>
				</div>
				<div class="col2 colHeader">
					二维码图
				</div>
			</div>
			<div class="row">
				<div class="col">
					<el-input v-model="source" type="textarea" :rows="10" placeholder="请输入要生成二维码的内容"></el-input>
				</div>
				<div class="col2 imgBox">
					<img :src="image"/>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import QRCode from 'qrcode'
    export default {
        data () {
            return {
				source: '',
				image: '',
				options: {
					width: 150,
					margin: 1,
					scale: 1,
					mask: 0,
					color: {
					  dark:"#000000FF",
					  light:"#FFFFFFFF"
					}
				}
				
            }
        },
		watch: {
			source (val) {
				this.genQrcode(val)
			}
		},
        created () {
			
        },
        mounted () {
        },
        methods: {
			genQrcode (v) {
				if (!v) {
					this.image = ''
					return
				}
				QRCode.toDataURL(this.source, this.options).then(url => {
				    this.image = url
				}).catch(err => {
				    console.error(err)
				})
			}
        }
    }
</script>
<style scoped lang="scss">
	/deep/.el-input {
		width: 50px;
		margin-right: 10px;
		input {
			height: 33px;
			line-height: 33px;
			padding: 0 5px;
			text-align: left;
		}
	}
	.json {
		font-size: 12px;
		color: #333;
		.box {
			padding: 2px;
			width: 90%;
			margin: 0 auto;
			.row {
				padding: 2px;
				display: flex;
				line-height: 40px;
				.col, .col2 {
					width: 90%;
					text-align: center;
				}
				.col2 {
					margin-left: 30px;
					img{
						padding: 0px;
						margin: 0px;
						border: none;
						max-width: 200px;
						max-height: 200px;
					}
				}
				.imgBox {
					border:1px solid #eee;
					display: flex;
					justify-content: space-around;
				}
				.colHeader {
					background-color: #eee;
				}
			}
		}
	}
</style>