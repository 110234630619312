<template>
	<el-container>
		<el-header style="height:30px;" v-if="NotChat">
			<el-menu :default-active="activeIndex" mode="horizontal">
			  <el-menu-item v-for="m in menus" :index="m.index" ><a :href="m.path">{{ m.meta.title }}</a></el-menu-item>
			</el-menu>
		</el-header>
		<el-main>
			<div id="app">
				<router-view></router-view>
			</div>			
		</el-main>
	</el-container>
</template>
<script>
  import Menus from './js/menus.js'
  export default {
    data() {
      return {
        activeIndex: '1',
		menus: []
	  }
    },
	computed: {
		NotChat: function(){
			return this.$route.path !== '/chat'
		}
	},
	created () {
		Menus.forEach(e => {
			if (e.meta && e.meta.title && e.index) {
				this.menus.push({...e})
			}
		})
		this.menus.forEach(e=>{
			if (e.path === this.$route.path) {
				this.activeIndex = e.index
			}
		})
	},
    methods: {
    }
  }
</script>
<style scoped lang="scss">
	.el-menu--horizontal{
		.el-menu-item {
			height: 30px;
			line-height: 30px;
		}
	}
	#app {
	}
</style>