<template>
	<div class="json">
		<div class="box">
			<div class="row rowHeader">
				<b>JSON</b>
			</div>
			<div class="row">
				<el-input v-model="source" type="textarea" :rows="10"></el-input>
			</div>
			<div class="row rowHeader">
				<b>表格</b>
			</div>
			<div class="row">
				<table id="table">
					<tr>
						<th v-for="col in columns">{{ col }}</th>
					</tr>
					<tr v-for="(row,idx) of datas">
						<td v-for="col in row">{{ col }}</td>
					</tr>
				</table>
			</div>
		</div>
	</div>
</template>

<script>
    export default {
        data () {
            return {
				source: '',
				columns: [],
				datas: []
            }
        },
		watch: {
			source (val) {
				this.toTable(val)
			}
		},
        created () {
			
        },
        mounted () {
        },
        methods: {
			toTable (v) {
				try{
					if (!v) {
						return
					}
					let e = JSON.parse(v)
					if(typeof(e) ==='object' && e.length > 0) {
						for(let p of e){
							let t = p.title;
							try {
								t = p.title.replace(/[\uD800-\uDBFF][\uDC00-\uDFFF]/g, '')
							} catch(e){}
							let obj = {...p, title: t}
							delete obj.titleImg
							delete obj.founderImg
							this.datas.push(obj);
						}
						for(let k in e[0]) {
							if(k === 'titleImg' || k === 'founderImg') {
								continue;
							}
							this.columns.push(k);
						}
					}
				}catch(e) {
					console.log(e)
				}
			}
        }
    }
</script>
<style scoped lang="scss">
	/deep/.el-input {
		input {
			height: 33px;
			line-height: 33px;
		}
	}
	.json {
		font-size: 12px;
		color: #333;
		.box {
			padding: 2px;
			width: 90%;
			margin: 0 auto;
			.row {
				padding: 2px;
				display: flex;
				line-height: 40px;
				.col, .col2 {
					width: 90%;
					text-align: center;
				}
				.col2 {
					margin-left: 30px;
				}
				.colHeader {
					background-color: #eee;
				}
			}
			.rowHeader {
				background-color: #eee;
				padding-left:10px;
			}
		}
	}
</style>