<template>
	<el-container>
		<el-main>
			ff
		</el-main>
		<el-footer :height="50">
			<el-input v-model="msg" type="textarea" :rows="2"></el-input>
		</el-footer>
	</el-container>
</template>

<script>
    export default {
        data () {
            return {
				msg: '',
				list: []
            }
        },
        created () {
			
        },
        mounted () {
        },
        methods: {
			send () {
				
			}
        }
    }
</script>
<style scoped lang="scss" scoped>
	.el-container {
		height: 100%;
	}
	/deep/.el-input {
		input {
			height: 33px;
			line-height: 33px;
		}
	}
	.chat {
		font-size: 12px;
		color: #333;
		.box {
			padding: 2px;
			width: 90%;
			margin: 0 auto;
			.row {
				padding: 2px;
				display: flex;
				line-height: 40px;
			}
		}
	}
</style>