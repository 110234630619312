<template>
	<div>
	</div>
</template>


<script>
    export default {
        data () {
            return {
            }
        },
        created(){
        },
        mounted(){
        },
        methods:{
        }
    }
</script>

<style>
</style>