<template>
	<div class="json">
		<div class="box">
			<div class="row">
				<b>缩进：</b>
				<el-select v-model="padding" @change="changePadding" style="width:100px">
					<el-option v-for="item in paddings" :key="item.value" :label="item.label" :value="item.value"></el-option>
				</el-select>
			</div>
			<div class="row">
				<div class="col colHeader">
					<b>JSON</b>
				</div>
				<div class="col2 colHeader">
					<b>格式化后</b>
				</div>
			</div>
			<div class="row">
				<div class="col">
					<el-input v-model="source" type="textarea" :rows="30"></el-input>
				</div>
				<div class="col2">
					<el-input v-model="formated" type="textarea" :rows="30"></el-input>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
    export default {
        data () {
            return {
				source: '',
				formated: '',
				padding: '  ',
				paddings: [{
					label: '2个空格',
					value: '  '
				}, {
					label: '4个空格',
					value: '    '
				}, {
					label: 'TAB',
					value: '\t'
				}]
            }
        },
		watch: {
			source (val) {
				this.formatJSON(val)
			}
		},
        created () {
			
        },
        mounted () {
        },
        methods: {
			formatJSON (v) {
				try{
					if (!v) {
						this.formated = ''
						return
					}
					let e = JSON.parse(v)
					if(typeof(e) === 'object' || typeof(e) ==='array') {
						this.formated = JSON.stringify(e, null, this.padding)
					}
				}catch(e) {
					this.formated = e.message
				}
			},
			changePadding () {
				if (this.source === '') {
					return
				}
				this.formatJSON(this.source)
			}
        }
    }
</script>
<style scoped lang="scss">
	/deep/.el-input {
		input {
			height: 33px;
			line-height: 33px;
		}
	}
	.json {
		font-size: 12px;
		color: #333;
		.box {
			padding: 2px;
			width: 90%;
			margin: 0 auto;
			.row {
				padding: 2px;
				display: flex;
				line-height: 40px;
				.col, .col2 {
					width: 90%;
					text-align: center;
				}
				.col2 {
					margin-left: 30px;
				}
				.colHeader {
					background-color: #eee;
				}
			}
		}
	}
</style>