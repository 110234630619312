<template>
	<div class="timestamp">
		<div class="box">
			<div class="row">
				<div class="label"></div>
				<div class="col">
					时间
				</div>
				<div class="operate">
				</div>
				<div class="col">
					时间戳
				</div>
			</div>
			<div class="row">
				<div class="label">当前</div>
				<div class="col">
					<el-input v-model="now" placeholder="" readonly></el-input>
				</div>
				<div class="operate"></div>
				<div class="col">
					<el-input v-model="nowTimestamp" placeholder="" readonly></el-input>
				</div>
			</div>
			<div class="row">
				<div class="label">转换</div>
				<div class="col">
					<el-input v-model="time" ></el-input>
				</div>
				<div class="operate">
					<el-button size="small" type="primary" @click="toTimestamp">&gt;</el-button>
					<el-button size="small" type="primary" @click="toTime">&lt;</el-button>
				</div>
				<div class="col">
					<el-input v-model="timestamp" maxlength="10"></el-input>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
    export default {
        data () {
            return {
				now: '',
				nowTimestamp: '',
				time: '',
				timestamp: '',
            }
        },
        created(){
			setInterval(() => {
				this.refreshDt()
			}, 1000);
			this.time = this.formatDt(new Date())
        },
        mounted(){
        },
        methods:{
			refreshDt () {
				let dt = new Date()
				this.now = this.formatDt(dt)
				this.nowTimestamp = Math.floor(dt.getTime()/1000)
			},
			formatDt (dt) {
				let Y = dt.getFullYear();
				let M = (dt.getMonth()+1 < 10 ? '0'+(dt.getMonth()+1) : dt.getMonth()+1);
				let D = dt.getDate()<10 ? '0'+dt.getDate() : dt.getDate();
				let H = dt.getHours()<10 ? '0'+dt.getHours() : dt.getHours();
				let m = dt.getMinutes()<10 ? '0'+dt.getMinutes() : dt.getMinutes();
				let s = dt.getSeconds()<10 ? '0'+dt.getSeconds() : dt.getSeconds();
				return (Y + '-' + M + '-' + D + ' ' + H + ':' + m + ':' + s);
			},
			toTimestamp () {
				let dt = new Date(this.time)
				this.timestamp = Math.floor(dt.getTime()/1000)
			},
			toTime () {
				let dt = new Date(this.timestamp*1000)
				this.time = this.formatDt(dt)
			}
        }
    }
</script>
<style scoped lang="scss">
	/deep/.el-input {
		input {
			height: 33px;
			line-height: 33px;
		}
	}
	.timestamp {
		font-size: 14px;
		color: #333;
		.box {
			padding: 2px;
			width: 500px;
			margin: 0 auto;
			.row {
				padding: 2px;
				display: flex;
				line-height: 40px;
				height: 40px;
				.label {
					width: 40px;
					text-align: center;
					margin: 2px;
					font-weight: bold;
				}
				.operate {
					width: 90px;
					margin: 2px;
					align-items: center;
				}
				.col {
					width: 180px;
					margin: 2px;
					text-align: center;
				}
			}
		}
	}
</style>