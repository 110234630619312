import Vue from 'vue'
import Router from 'vue-router'
import Menus from '../js/menus.js'
Vue.use(Router)

const router = new Router({
    mode: "history",
    routes: Menus
})

//beforeEach是router的钩子函数，在进入路由前执行
router.beforeEach((to, from, next) => {
  if (to.meta.title) { //判断是否有标题
    document.title = to.meta.title
  }
  next()
})

export default router;